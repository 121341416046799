import React from 'react';
import {Dropdown, DropdownOptionProps} from 'wix-ui-tpa/Dropdown';
import {getDropdownOptionsFromSelections} from './OptionsDropdownUtils';
import {classes} from '../../OptionsDropdown/OptionsDropdown.st.css';
import s from '../../OptionsDropdown/OptionsDropdown.scss';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProvidedGlobalProps, withGlobalProps} from '../../../../providers/globalPropsProvider';
import {IProductOptionSelectionItem, IProductOptionsItem} from '@wix/wixstores-client-core/dist/es/src/types/product';
import {UserInputType} from '../../../../constants';
import {ErrorTooltipProvider} from '../../../ErrorTooltipProvider/ErrorTooltipProvider';

export interface OptionsDropdownProps {
  dropdown: IProductOptionsItem;
  onSelect(selected: IProductOptionSelectionItem[]): void;
  selected?: IProductOptionSelectionItem;
  errorIndex: number;
}

export enum DataHook {
  OptionsDropdown = 'options-dropdown',
}

/**
 * @deprecated Remove when experiment is merged
 * @see https://jira.wixpress.com/browse/EE-26700
 */
@withGlobalProps
@withTranslations('globals.texts')
export class OptionsDropdown extends React.Component<
  OptionsDropdownProps & ProvidedGlobalProps & IProvidedTranslationProps
> {
  private readonly onSelect = (nextSelected: DropdownOptionProps) => {
    const {
      onSelect,
      dropdown: {selections},
    } = this.props;
    const selection = selections.find(({id}) => id.toString() === nextSelected.id);
    onSelect([selection]);
  };

  public render(): JSX.Element {
    const {
      t,
      selected,
      errorIndex,
      dropdown: {title, selections},
      globals: {errorPlacement, isProductSubmitted, userInputErrors},
    } = this.props;

    const options = getDropdownOptionsFromSelections(selections);

    return (
      <div>
        <div data-hook="options-dropdown-title" className={s.title}>
          {title}
        </div>
        <ErrorTooltipProvider
          className={s.dropDownErrorTooltip}
          content={t('SELECT_OPTION_WARNING', {optionTitle: title})}
          placement={errorPlacement}
          show={isProductSubmitted && userInputErrors[UserInputType.Selection][errorIndex]}>
          <Dropdown
            className={classes.root}
            options={options}
            onChange={this.onSelect}
            placeholder={t('SELECT_PLACE_HOLDER')}
            initialSelectedId={selected ? `${selected.id}` : ''}
            data-hook={DataHook.OptionsDropdown}
            mobileNativeSelect
          />
        </ErrorTooltipProvider>
      </div>
    );
  }
}
